<template>
	<div class="main-content">
		<page-header title="Marshal Management" pre-title="Overview" :loading="false">
			<template slot="actions">
				<button class="btn border-dark mx-2"><router-link class="text-dark " :to="{ name: 'MarshalSummary' }">Marshal
					Summary</router-link></button>
				<router-link :to="{ name: 'ManageMarshal' }" class="btn btn-primary"> + Add Marshal</router-link>
			</template>
		</page-header>
		<!-- <b-modal>
      <template #modal-title>
      Make Bus Captain
    </template>
    </b-modal> -->
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-contentk-between">
							<div class="input-group input-group-flush d-flex flex-row-reverse">
								<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
								<span class="input-group-text border-0">
									<i class="fe fe-search"></i>
								</span>
							</div>
							<div class="col-auto" v-if="marshals.length">
								<v-datepicker style="width: 100%" v-model="filter.range" placeholder="Today" range></v-datepicker>
							</div>
							<div class="">
								<download-button v-if="marshals.length" :downloading="downloadingReport" label="Download Report"
									@download="downloadReport()"></download-button>
							</div>
						</div>
						<div class="col px-0 d-flex">
							<div class="col"></div>
							<div class="col-auto">
								<filter-button :options="statusBar" :loading="false">
									<template #label>
										<span class="text-grey">Status:</span> <span class="text-capitalize">{{ statusFilter }}</span>
									</template>

									<template #option="data">
										<span class="w-100 d-inline" @click="selectSortOrder(data.value)">
											{{ data.value }}
										</span>
									</template>
								</filter-button>
							</div>
						</div>
						<b-table striped hover selectable responsive :items="marshals" :fields="fields" :current-page="currentPage"
							:busy="fetchingMarshals" @row-clicked="viewMarshals">
							<template #table-busy>
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-2"><strong>Loading...</strong></p>
								</div>
							</template>
							<template #empty>
								<p class="text-center text-secondary">No records available</p>
							</template>
							<template #cell(user)="data">
								<p class="font-weight-bold" style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ `${data.item.fname} ${data.item.lname}` }}</router-link>
								</p>
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{ name: 'ShowMarshal', params: { marshalId: data.item.id } }">{{
										data.item.email }}</router-link>
								</p>
							</template>
							<template #cell(phone_number)="data">
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ data.item.phone }}</router-link>
								</p>
							</template>
							<template #cell(routes_assigned)="data">
								<span v-for="(route, index) in data.item.itineraries" :key="index">
									<small class="route_code p-2 font-weight-bolder bg-secondary rounded-pill mx-2">{{ route }}</small>
								</span>
							</template>
							<template #cell(average_rating)="data">
								<average-rating :rating="data.item.average || 0" :total-trips="data.item.trip_count"></average-rating>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import PageHeader from '@/components/layout/PageHeader'
import AverageRating from '@/components/core/AverageRating.vue'
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import { joinWithRatingsData } from '@/utils/helpers'
import DownloadButton from '@/components/core/DownloadButton.vue'
import FilterButton from '@/components/core/FilterButton'
import _ from 'lodash'

export default {
  components: {
    AverageRating,
    DownloadButton,
    PageHeader,
    FilterButton
  },
  data() {
    return {
      fields: [
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'phone_number',
          label: 'Phone Number'
        },
        {
          key: 'routes_assigned',
          label: 'Routes Assigned'
        },
        {
          key: 'average_rating',
          label: 'Marshal Rating'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      marshals: [],
      fetchingMarshals: true,
      search: '',
      processing: false,
      statusFilter: 'active',
      downloadingReport: false,
      filter: {
        range: []
      },
      statusBar: ['Active', 'Inactive', 'Suspend']
    }
  },
  created() {
    this.initMarshals()
    this.debouncedCallback = _.debounce(() => {
      this.initMarshals(true)
    }, 1000)
  },
  computed: {
    filteredMarshals() {
      const search = this.search.toLowerCase()

      const marshals = this.marshals.filter((marshal) => {
        return (
          marshal.fname.toLowerCase().includes(search) ||
          marshal.lname.toLowerCase().includes(search) ||
          marshal.email.toLowerCase().includes(search) ||
          marshal.phone_number.toLowerCase().includes(search)
        )
      })

      return marshals
    },

    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },

    dateFilterValid() {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    currentPage() {
      this.initMarshals()
    },
    search() {
      this.debouncedCallback()
    },
    statusFilter() {
      this.initMarshals(true)
    },
    dateFilterValid() {
      this.initMarshals(true)
    }
  },
  methods: {
    selectSortOrder(value) {
      this.statusFilter = value
    },
    async initMarshals() {
      this.fetchingMarshals = true
      const dateRange = this.filter.range.map((date) =>
        moment(date).format('YYYY-MM-DD')
      )

      const url = `/v1/bus-marshals${this.search ? '&search=' : ''}?limit=${this.perPage}&page=${this.currentPage}&metadata=true&sort[id]=desc&related=itineraries&status=${this.statusFilter}`
      const filter_url = `/v1/bus-marshals?metadata=true&page=${this.currentPage}&limit=${this.perPage}&related=itineraries&start_date_filter=${dateRange[0]}&end_date_filter=${dateRange[1]}`
      const request = this.search
        ? this.axios.post(url, {
          marshal: this.search || '',
          creation_date_start: this.dateFilterValid ? dateRange[0] : null,
          creation_date_end: this.dateFilterValid ? dateRange[1] : null
        })
        : this.dateFilterValid ? this.axios.get(filter_url) : this.axios.get(url)
      request
        .then((res) => {
          this.marshals = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.marshals
        })
        .finally(() => (this.fetchingMarshals = false))
    },

    reset() {
      this.processing = false
      this.selectedDrivers = []
      this.checkAllDrivers = false
    },
    viewDriver(driver) {
      this.$router.push({ name: 'ShowDriver', params: { driverId: driver.id } })
    },
    downloadReport() {
      this.downloadingReport = true
      const csvData = this.marshals.map((data) => {
        const marshalRoutes = data.routes_assigned.map((eachMarshal) => {
          return eachMarshal
        })
        return {
          name: data.fname + ' ' + data.lname,
          average_rating: data.average_rating,
          phone_number: data.phone_number,
          routes_assigned: marshalRoutes,
          status: data.active == 1 ? 'Active' : data.active == 0 ? 'Inactive' : 'suspended'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRange.length ? 'Drivers List' : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRange.length ? 'Drivers List' : `Drivers List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Name',
          'Average Rating',
          'Phone',
          'Routes assigned',
          'Status'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    viewMarshals(marshal) {
      this.$router.push({ name: 'ShowMarshal', params: { marshalId: marshal.id } })
    }
  },
  beforeDestroy() {
    this.debouncedCallback.cancel()
  }
}
</script>
<style lang="scss" scoped>
@use "src/assets/scss/b-table";

.route_code {
  background: #D3DCE6 !important;
  color: black;
  font-size: 12px;
}
</style>
